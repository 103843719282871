<template>
  <main class="main">
    <nav class="breadcrumb-nav">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/">Home</router-link>
          </li>
          <li class="breadcrumb-item active">{{ $t("blog") }}</li>
        </ol>
        <h4>{{ $t("blog") }}</h4>
      </div>
    </nav>

    <div class="page-content">
      <div class="container" v-if="blogs.length === 0">
        <p class="no-results">No blogs found.</p>
      </div>
      <div class="container" v-else>
        <div class="row mb-2">
          <div class="col-md-12">
            <pagination-comp
              :per-page="perPage"
              :total="totalCount"
              @handlePerPage="handlePerPage"
            />
          </div>
        </div>
        <div class="row skeleton-body" :class="{ loaded: !blogs.length }">
          <div class="col-lg-8">
            <div class="card rounded-corner">
              <div class="card-body p-4">
                <template v-if="!blogs.length">
                  <div
                    class="skel-list-post mb-6"
                    v-for="(_, index) in [1, 2, 3, 4, 5, 6]"
                    :key="'skel' + index"
                  ></div>
                </template>

                <template v-else-if="!isFilterActive && blogs.length > 0">
                  <!-- <h1>blogs</h1> -->
                  <div class="row">
                    <div
                      class="col-md-6 col-6 px-3"
                      v-for="(blog, index) in blogs"
                      :key="index"
                    >
                      <blog-four :blog="blog" class="mb-2"></blog-four>
                    </div>
                  </div>
                </template>

                <template
                  v-else-if="isFilterActive && filteredBlogs.length > 0"
                >
                  <!-- <h1>f blogs</h1> -->
                  <div class="row">
                    <div
                      class="col-md-6 col-6 px-3"
                      v-for="(filteredBlog, index) in filteredBlogs"
                      :key="index"
                    >
                      <blog-four :blog="filteredBlog" class="mb-2"></blog-four>
                    </div>
                  </div>
                </template>

                <template v-else>
                  <div>
                    <p class="blogs-info">
                      No blogs were found matching your selection.
                    </p>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <!-- sticky-container -->
          <aside class="col-lg-4">
            <!-- v-sticky="shouldSticky" sticky-offset="{top: 69}" -->
            <div class="sticky-content">
              <button
                class="sidebar-fixed-toggler right"
                @click="toggleSidebar"
                v-if="isSidebar"
              >
                <i class="icon-cog"></i>
              </button>

              <div class="sidebar-filter-overlay" @click="hideSidebar"></div>
              <blog-sidebar
                :blog-categories="blogCategories"
                type="listing"
                :class="isSidebar ? 'sidebar-filter right' : ''"
              ></blog-sidebar>
            </div>
          </aside>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <pagination-comp
              :per-page="perPage"
              :total="totalCount"
              @handlePerPage="handlePerPage"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
// import Sticky from 'vue-sticky-directive';
import BlogFour from "@/esf_weert_mobilesupplies/core/components/elements/blogs/BlogFour";
import BlogSidebar from "@/esf_weert_mobilesupplies/core/components/partial/blog/BlogSidebar";
import PaginationComp from "@/esf_weert_mobilesupplies/core/components/elements/PaginationComp";
import config from "../../config";
// import Repository, { baseUrl } from '@/esf_weert_mobilesupplies/repositories/repository.js';

export default {
  components: {
    BlogFour,
    BlogSidebar,
    PaginationComp,
  },
  // directives: {
  // 	Sticky
  // },
  data: function () {
    return {
      blogs: [],
      filteredBlogs: [],
      isFilterActive: false,
      activeCategory: null,
      sorting: "newest",
      page: 1,
      // pagination
      perPage: 24,
      totalCount: 0,
      // pagination
      certainTag: null,
      certainCategory: null,
      isSidebar: false,
      shouldSticky: false,
    };
  },
  watch: {
    sorting(currValue, oldValue) {
      if (currValue != oldValue) {
        this.sortBlogs();
      }
    },
    $route(newVal) {
      const {
        name,
        params: { category_id },
      } = newVal;
      if (name == "blog-category" && category_id) {
        this.getBlogsByCategoryId(category_id);
      } else {
        this.getAllBlogs();
      }
    },
    totalBlogs(items) {
      this.totalCount = items.length;
    },
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "title",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_title
            : "Blog",
        },
        {
          name: "keywords",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_keywords
            : "Blog",
        },
        {
          name: "description",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_description
            : "Lijst van blog posts",
        },
      ],
      //test 3
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.cmsDefaultPageContent
        ? this.cmsDefaultPageContent.meta_title
        : "Blog",
      // all titles will be injected into this template
      link: [{ rel: "canonical", href: config.app_url + this.$route.fullPath }],
    };
  },
  async created() {
    await this.getAllBlogsCategory();

    const {
      name,
      params: { category_id },
    } = this.$route;

    if (name == "blog-category" && category_id) {
      this.getBlogsByCategoryId(category_id);
    } else {
      this.setAllBlogs();
    }
  },
  mounted: function () {
    this.page = this.$store.getters["blog_additional/getTotalPages"];
    // this.perPage = this.$store.getters["blog_additional/getBlogsPerPage"];
    this.totalCount = this.$store.getters["blog_additional/getTotalBlogs"];
    // this.blogs = this.$store.getters["blog_additional/getCurrentPage"];
    this.resizeHandler();
    window.addEventListener("resize", this.resizeHandler, {
      passive: true,
    });
    this.stickyHandle();
    window.addEventListener("resize", this.stickyHandle, { passive: true });
  },
  destroyed: function () {
    window.removeEventListener("resize", this.resizeHandler);
    window.removeEventListener("resize", this.stickyHandle);
  },
  computed: {
    totalBlogs() {
      return this.$store.getters["blog_additional/getTotalBlogs"];
    },
    currentPage() {
      return this.$store.getters["blog_additional/getCurrentPage"];
    },
    getTotalPages() {
      return this.$store.getters["blog_additional/getTotalPages"];
    },
    blogCategories() {
      return this.$store.getters["blog_additional/getBlogCategories"];
    },
  },
  methods: {
    setAllBlogs() {
      const list = this.$store.getters["blog_additional/getList"];

      this.blogs = [];
      list.forEach((element) => {
        element.items.forEach((item) => {
          const isExist = this.blogs.find((el) => el.post_id === item.post_id);
          const currentLength = this.page * this.perPage;
          if (!isExist && this.blogs.length < currentLength) {
            this.blogs = [...this.blogs, item];
          }
        });
      });
      this.sortBlogs();
      this.setBreadCrumbs();
    },
    getAllBlogs() {
      this.page = 1;
      this.isFilterActive = false;
      this.activeCategory = null;
      this.setAllBlogs();
    },
    async getAllBlogsCategory() {
      this.isLoading = true;
      await this.$store.dispatch("blog_additional/loadBlogCategories");
      this.isLoading = false;
    },
    async getBlogsByCategoryId(identifier) {
      const category = this.blogCategories.find(
        (item) => item.identifier === identifier
      );

      if (category != null) {
        const categoryId = category.category_id;
        this.activeCategory = category;
        this.isFilterActive = true;

        this.isLoading = true;
        await this.$store.dispatch("blog_additional/loadBlogsByFilter", {
          categoryId,
        });
        this.isLoading = false;

        this.page = 1;
        this.filteredBlogs = this.allFilteredBlogs.slice(0, this.perPage);

        this.sortBlogs();
        this.setBreadCrumbs();
      }
    },
    // async loadMore() {
    // 	if (!this.isFilterActive) {
    // 		this.page++;
    // 		if (this.page * this.perPage > this.blog.length) {
    // 			await this.$store.dispatch("blog_additional/goToPage", {
    // 				pageNr: this.page,
    // 			});
    // 		}
    // 		this.setAllBlogs();
    // 	} else {
    // 		this.filteredBlogs = this.allFilteredBlogs.slice(
    // 			0,
    // 			this.filteredBlogs.length + this.perPage
    // 		);
    // 	}
    // 	this.sortBlogs();
    // },
    // async searchBlog(event) {
    // 	if (this.searchTimer != null) {
    // 		clearTimeout(this.searchTimer);
    // 	}
    // 	this.searchTimer = setTimeout(async () => {
    // 		const searchVal = event.target.value;
    // 		if (searchVal.length > 0) {
    // 			this.isFilterActive = true;

    // 			let payload = { searchVal };

    // 			if (this.activeCategory !== null) {
    // 				const categoryId = this.activeCategory.category_id;
    // 				payload = { ...payload, categoryId };
    // 			}

    // 			this.isLoading = true;
    // 			await this.$store.dispatch(
    // 				"blog_additional/loadBlogsByFilter",
    // 				payload
    // 			);
    // 			this.isLoading = false;

    // 			this.filteredBlogs = this.allFilteredBlogs.slice(
    // 				0,
    // 				this.perPage
    // 			);
    // 		} else {
    // 			if (this.activeCategory !== null) {
    // 				this.getBlogsByCategoryId(this.activeCategory.identifier);
    // 			} else {
    // 				this.isFilterActive = false;
    // 				this.setAllBlogs();
    // 			}
    // 		}
    // 	}, 300);
    // },
    sortBlogs() {
      if (!this.isFilterActive) {
        const blogs = [...this.blogs];
        blogs.sort((a, b) => {
          if (this.sorting == "newest") {
            if (a.creation_time > b.creation_time) {
              return -1;
            }
            if (a.creation_time < b.creation_time) {
              return 1;
            }
          } else if (this.sorting == "oldest") {
            if (a.creation_time < b.creation_time) {
              return -1;
            }
            if (a.creation_time > b.creation_time) {
              return 1;
            }
          }
          return 0;
        });
        this.blogs = blogs;
      } else {
        const blogs = [...this.filteredBlogs];
        blogs.sort((a, b) => {
          if (this.sorting == "newest") {
            if (a.creation_time > b.creation_time) {
              return -1;
            }
            if (a.creation_time < b.creation_time) {
              return 1;
            }
          } else if (this.sorting == "oldest") {
            if (a.creation_time < b.creation_time) {
              return -1;
            }
            if (a.creation_time > b.creation_time) {
              return 1;
            }
          }
          return 0;
        });
        this.filteredBlogs = blogs;
      }
    },
    setBreadCrumbs() {
      let activeCategoryName = "Alles";

      if (this.activeCategory != null) {
        activeCategoryName = this.activeCategory.title;
      }

      const bcrumb = {
        current: activeCategoryName,
        routes: [{ name: "Blog", route_link: "/blog" }],
      };
      this.$store.commit("breadcrumbs/set", bcrumb);
    },
    toggleSidebar: function () {
      if (
        document
          .querySelector("body")
          .classList.contains("sidebar-filter-active")
      ) {
        document
          .querySelector("body")
          .classList.remove("sidebar-filter-active");
      } else {
        document.querySelector("body").classList.add("sidebar-filter-active");
      }
    },

    hideSidebar: function () {
      document.querySelector("body").classList.remove("sidebar-filter-active");
    },
    resizeHandler: function () {
      if (window.innerWidth > 992) this.isSidebar = false;
      else this.isSidebar = true;
    },
    stickyHandle: function () {
      if (window.innerWidth > 991) this.shouldSticky = true;
      else this.shouldSticky = false;
    },
    // pagination
    handlePerPage: function (count) {
      this.perPage = count;
    },
    // pagination
  },
};
</script>
