<template>
  <div class="blog-sidebar sidebar-blog">
    <!-- <div class="widget widget-search">
			<form action="#">
				<label for="ws" class="sr-only">Search in blog</label>
				<input type="search" class="form-control" name="ws" id="ws" placeholder="Search in blog" required />
				<button type="submit" class="btn">
					<i class="icon-search"></i>
					<span class="sr-only">Search</span>
				</button>
			</form>
		</div> -->
    <div class="widget widget-cats">
      <h4 class="text-primary text-capitalize">{{ $t("popular_blogs") }}</h4>

      <div
        class="skel-post-sidebar-category"
        v-if="!blogCategories.length"
      ></div>

      <ul>
        <li v-for="(cat, index) in blogCategories" :key="index">
          <router-link :to="'/blog/categorie/' + cat.identifier + '/'">
            {{ cat.title }}
            <!-- <span>{{ cat.posts_count }}</span> -->
          </router-link>
        </li>
      </ul>
    </div>
    <!-- 
		<div class="widget">
			<h3 class="widget-title">Popular Posts</h3>

			<ul class="posts-list">
				<li>
					<figure>
						<router-link to="/blog/single/default/cras-ornare-tristique-elit.">
							<img v-lazy="`./images/blog/sidebar/post-1.jpg`" alt="post" width="80" height="80" />
						</router-link>
					</figure>

					<div>
						<span>Nov 22, 2018</span>
						<h4>
							<router-link to="/blog/single/default/cras-ornare-tristique-elit.">Aliquam tincidunt mauris
								eurisus.</router-link>
						</h4>
					</div>
				</li>
				<li>
					<figure>
						<router-link to="/blog/single/default/cras-ornare-tristique-elit.">
							<img v-lazy="`./images/blog/sidebar/post-2.jpg`" alt="post" width="80" height="80" />
						</router-link>
					</figure>

					<div>
						<span>Nov 19, 2018</span>
						<h4>
							<router-link to="/blog/single/default/cras-ornare-tristique-elit.">Cras ornare tristique
								elit.</router-link>
						</h4>
					</div>
				</li>
				<li>
					<figure>
						<router-link to="/blog/single/default/cras-ornare-tristique-elit.">
							<img v-lazy="`./images/blog/sidebar/post-3.jpg`" alt="post" width="80" height="80" />
						</router-link>
					</figure>

					<div>
						<span>Nov 12, 2018</span>
						<h4>
							<router-link to="/blog/single/default/cras-ornare-tristique-elit.">Vivamus vestibulum ntulla
								nec ante.</router-link>
						</h4>
					</div>
				</li>
				<li>
					<figure>
						<router-link to="/blog/single/default/cras-ornare-tristique-elit.">
							<img v-lazy="`./images/blog/sidebar/post-4.jpg`" alt="post" width="80" height="80" />
						</router-link>
					</figure>

					<div>
						<span>Nov 25, 2018</span>
						<h4>
							<router-link to="/blog/single/default/cras-ornare-tristique-elit.">Donec quis dui at dolor
								tempor interdum.</router-link>
						</h4>
					</div>
				</li>
			</ul>
		</div>

		<div class="widget widget-banner-sidebar">
			<div class="banner-sidebar-title">ad box 280 x 280</div>

			<div class="banner-sidebar banner-overlay">
				<router-link to="/shop/sidebar/list">
					<img v-lazy="`./images/blog/sidebar/banner.jpg`" alt="banner" width="280" height="280" />
				</router-link>
			</div>
		</div>

		<div class="widget">
			<h3 class="widget-title">Browse Tags</h3>

			<div class="tagcloud">
				<a href="#">fashion</a>
				<a href="#">style</a>
				<a href="#">women</a>
				<a href="#">photography</a>
				<a href="#">travel</a>
				<a href="#">shopping</a>
				<a href="#">hobbies</a>
			</div>
		</div>

		<div class="widget widget-text">
			<h3 class="widget-title">About Blog</h3>

			<div class="widget-text-content">
				<p>Vestibulum volutpat, lacus a ultrices sagittis, mi neque euismod dui, pulvinar nunc sapien ornare
					nisl.</p>
			</div>
		</div> -->
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      category: this.$route.query.category,
    };
  },
  props: {
    type: String,
    blogCategories: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
};
</script>
